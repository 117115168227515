import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { addUserToGroup, signUp } from "./userFunctions";
import { useToasts } from "react-toast-notifications";
import { UserMngmentTableState } from "../Visualizations/Tables/tableState";

export const AddUserDialog = ({ open, onClose, refreshTable }) => {
  const { addToast } = useToasts();
  const [newUser, setNewUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    group: "",
  });

  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    group: "",
  });

  const handleTextChange = (e) => {
    const field = e.target.name;
    const userCopy = { ...newUser };
    userCopy[field] = e.target.value;
    setNewUser(userCopy);
  };

  const handleAddUser = async () => {
    let validEntry = true;
    const errors = {
      email: "",
      firstName: "",
      lastName: "",
      group: "",
    };

    if (newUser.email === "") {
      errors.email = "Email is required";
      validEntry = false;
    } else {
      errors.email = "";
    }

    if (newUser.firstName === "") {
      errors.firstName = "First name is required";
      validEntry = false;
    } else {
      errors.firstName = "";
    }

    if (newUser.lastName === "") {
      errors.lastName = "Last name is required";
      validEntry = false;
    } else {
      errors.lastName = "";
    }

    if (newUser.group === "") {
      errors.group = "Group is required";
      validEntry = false;
    } else {
      errors.group = "";
    }

    if (
      errors.email === "" &&
      errors.firstName === "" &&
      errors.lastname === "" &&
      errors.group === ""
    ) {
      validEntry = true;
    }

    if (validEntry) {
      try {
        await signUp(
          newUser.email,
          newUser.firstName,
          newUser.lastName,
          newUser.group
        );

        if (newUser.group === "super") {
          await addUserToGroup(newUser.email, "admin");
        }

        refreshTable(UserMngmentTableState.DASHBOARD);
        addToast(`Successfully created user`, {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        addToast(error.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      onClose();
      setError(errors);
      setNewUser({
        email: "",
        firstName: "",
        lastName: "",
        group: "",
      });
    } else {
      setError(errors);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setError({
            email: "",
            firstName: "",
            lastName: "",
            group: "",
          });
          setNewUser({
            email: "",
            firstName: "",
            lastName: "",
            group: "",
          });
          onClose();
        }}
      >
        <DialogTitle>Add a User</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <DialogContentText>
            Fill in the information for the user that you would like to add. All
            fields are required
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            autoFocus
            error={error.email !== ""}
            name="email"
            label="Email"
            helperText={error.email}
            onChange={handleTextChange}
            value={newUser.email}
            variant="outlined"
            fullWidth
          >
            Email
          </TextField>
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            name="firstName"
            error={error.firstName !== ""}
            helperText={error.firstName}
            value={newUser.firstName}
            onChange={handleTextChange}
            label="First Name"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            name="lastName"
            error={error.lastName !== ""}
            value={newUser.lastName}
            helperText={error.lastName}
            onChange={handleTextChange}
            label="Last Name"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel error={error.group !== ""}>Group</InputLabel>
            <Select
              native
              name="group"
              error={error.group !== ""}
              value={newUser.group}
              onChange={handleTextChange}
              label="Group"
              inputProps={{
                name: "group",
                id: "group-select",
              }}
            >
              <option value={""}></option>
              <option value={"admin"}>Admin</option>
              <option value={"super"}>Super</option>
              <option value={"regular"}>Regular</option>
            </Select>
            <FormHelperText error={error.group !== ""}>
              {error.group}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setError({
                email: "",
                firstName: "",
                lastName: "",
                group: "",
              });
              setNewUser({
                email: "",
                firstName: "",
                lastName: "",
                group: "",
              });
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleAddUser}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
