import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { TableCard } from "./TableCard";

const useStyles = makeStyles(() => ({
  table: {
    height: "595px",
  },
}));

export const Table = ({
  headerTitle,
  children = null,
  handleTableOpen = null,
}) => {
  const classes = useStyles();

  return (
    <TableCard headerTitle={headerTitle} handleTableOpen={handleTableOpen}>
      <Box className={classes.table}>{children}</Box>
    </TableCard>
  );
};
