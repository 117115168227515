import React, { useEffect, useState } from "react";
import { ChartCard } from "./ChartCard";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Bar, Doughnut } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  chart: {
    minHeight: "300px",
  },
  chartMetric: {
    minHeight: "200px",
  },
  chartMetricWTitle: {
    minHeight: "175px",
  },
}));

export const Chart = ({
  data,
  processData,
  chartType = "bar",
  dashboardType,
  headerTitle,
  chartTitle = "",
  handleTableOpen = null,
  children = null,
}) => {
  const classes = useStyles();
  const [config, setConfig] = useState({});

  const Graph = () => {
    if (chartType === "bar") {
      return (
        <Bar
          data={config}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              y: {
                ticks: {
                  precision: 0,
                },
                stacked: true,
              },
              x: {
                stacked: true,
              },
            },
          }}
        />
      );
    } else if (chartType === "doughnut") {
      return (
        <Doughnut
          data={config}
          options={{
            maintainAspectRatio: false,
            responsive: true,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const getChartClass = () => {
    if (!children) {
      return classes.chart;
    } else if (children && chartTitle !== "") {
      return classes.chartMetricWTitle;
    } else {
      return classes.chartMetric;
    }
  };

  useEffect(() => {
    setConfig(processData(data));
  }, [data, processData]);

  return (
    <>
      <ChartCard
        headerTitle={headerTitle}
        dashboardType={dashboardType}
        handleTableOpen={handleTableOpen}
      >
        {children && (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
        <Grid item xs={12}>
          {chartTitle !== "" && (
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              color="textSecondary"
            >
              {chartTitle}
            </Typography>
          )}
          <Box className={getChartClass()}>
            <Graph />
          </Box>
        </Grid>
      </ChartCard>
    </>
  );
};
