import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const ParticipantsDataGrid = ({ data, group }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const formatColumns = () => {
      const newColumns = [
        (group.includes("admin") || group.includes("super")) && {
          field: "cohortid",
          headerName: "Cohort ID",
          width: 230,
        },
        group.includes("admin") &&
          group.length === 1 && {
            field: "firstname",
            headerName: "First Name",
            width: 230,
          },
        group.includes("admin") &&
          group.length === 1 && {
            field: "lastname",
            headerName: "Last Name",
            width: 230,
          },
        { field: "datestart", headerName: "Start Date", width: 230 },
      ];

      setColumns(newColumns);
    };

    const formatData = () => {
      const dataRows = [];
      for (const [key, value] of Object.entries(data)) {
        const entry = { ...value };
        entry.id = key;
        if (entry.datestart != null) {
          const startDate = new Date(entry.datestart);
          const rStartDate = startDate.toDateString();
          entry.datestart = rStartDate;
        }
        dataRows.push(entry);
      }
      setRows(dataRows);
    };

    formatColumns();
    formatData();
  }, [data, group]);

  return <DataGrid columns={columns} rows={rows} />;
};
