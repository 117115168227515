import React, { useState, useEffect } from "react";
import "../DashboardCard/DashboardCard.css";
import { Grid } from "@material-ui/core";
import { axiosFetch } from "../DashboardCard/dashboardQueries";
import { tableState } from "../Visualizations/Tables/tableState";
import TableDialog from "../Visualizations/Tables/TableDialog";
import { Chart } from "../Visualizations/Chart/Chart";
import {
  formatMoodSupportConfig,
  formatStudySupport,
} from "../Visualizations/Chart/chartFormatFunctions";
import axios from "axios";
import { DashboardType } from "../Visualizations/Chart/dashboardType";
import { Metric } from "../Visualizations/Metric/Metric";
import {
  getTotalStudySupportRequests,
  getTotalModdSupportRequests,
} from "../Visualizations/Metric/metricFunctions";
import { faSmile, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Table } from "../Visualizations/Tables/Table";
import { AllSupportDataGrid } from "../Visualizations/DataGrids/AllSupportDataGrid";

export const SupportGrid = ({ token, refreshing, setIsLoading, group }) => {
  const { REACT_APP_DB_ENDPOINT } = process.env;
  const [studySupport, setStudySupport] = useState([]);

  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [tblState, setTblState] = useState(tableState.STUDY_SUPPORT);
  const [tblData, setTblData] = useState([]);

  useEffect(() => {
    const queryDashboardData = async () => {
      const accessToken = token.jwtToken;
      try {
        const studySupportResult = await axiosFetch(
          accessToken,
          "studySupport"
        );

        setStudySupport(studySupportResult.data);
      } catch (error) {
        console.log("Error querying for dashboard data", error);
      }
      setIsLoading(false);
    };

    if (!refreshing) {
      queryDashboardData();
    }
  }, [REACT_APP_DB_ENDPOINT, token, refreshing, setIsLoading]);

  const handleTableDialogClose = () => {
    if (
      tblState === "Study Support Table" ||
      tblState === "Mood Support Table"
    ) {
      axios
        .get(`${REACT_APP_DB_ENDPOINT}/api/studySupport`, {
          headers: {
            accessToken: token.jwtToken,
          },
        })
        .then((res) => {
          setStudySupport(res.data);
        })
        .catch((err) => {
          console.log(
            "Error fetching covid total: ",
            err.response !== undefined ? err.response.data : err
          );
        });
    }
    setTableDialogOpen(false);
  };

  const handleTableDialogOpen = (newTableState) => {
    setTblState(newTableState);

    if (newTableState === tableState.STUDY_SUPPORT) {
      setTblData(studySupport);
    } else if (newTableState === tableState.MOOD_SUPPORT) {
      setTblData(studySupport);
    }

    setTableDialogOpen(true);
  };

  return (
    <div className="dashboard-charts-container">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6} xl={3}>
          <Chart
            data={studySupport}
            processData={formatMoodSupportConfig}
            chartType="bar"
            chartTitle="Pending vs. Resolved Requests"
            dashboardType={DashboardType.SUPPORT}
            headerTitle="Mood Support"
            handleTableOpen={() =>
              handleTableDialogOpen(tableState.MOOD_SUPPORT)
            }
          >
            <Metric
              data={studySupport}
              processData={getTotalModdSupportRequests}
              metricLabel="Mood Support Requests"
              icon={faSmile}
            />
          </Chart>
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <Chart
            data={studySupport}
            processData={formatStudySupport}
            chartType="bar"
            chartTitle="Pending vs. Resolved Requests"
            dashboardType={DashboardType.SUPPORT}
            headerTitle="Study Support"
            handleTableOpen={() =>
              handleTableDialogOpen(tableState.STUDY_SUPPORT)
            }
          >
            <Metric
              data={studySupport}
              processData={getTotalStudySupportRequests}
              metricLabel="Study Support Requests"
              icon={faPhone}
            />
          </Chart>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Table headerTitle="All Support Requests">
            <AllSupportDataGrid
              data={studySupport}
              token={token}
              group={group}
            />
          </Table>
        </Grid>
      </Grid>
      <TableDialog
        open={tableDialogOpen}
        onClose={handleTableDialogClose}
        tblState={tblState}
        data={tblData}
        token={token}
        group={group}
      />
    </div>
  );
};
