import "./App.css";
import SignIn from "./components/SignIn/SignIn";
import Amplify, { Auth } from "aws-amplify";
import awsmobile from "./aws-exports";
import { useEffect, useState } from "react";
import { authStatus } from "./authEnum";
import { ToastProvider } from "react-toast-notifications";
import Dashboard from "./components/Dashboard/Dashboard";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#eb1c24",
    },
  },
  app: {
    width: "100%",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none",
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: "-0.06px",
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
      margin: "0",
    },
  },
});

Amplify.configure(awsmobile);

// Initial auth state
let authStateInit = {
  authStatus: authStatus.UNINITIALIZED,
  user: undefined,
};

function App() {
  const [authState, setAuthState] = useState(authStateInit);
  const [accessToken, setAccessToken] = useState("");
  const [refreshing, setRefreshing] = useState(true);

  const handleAccessTokenChange = (token) => {
    setAccessToken(token);
    setRefreshing(false);
  };

  useEffect(() => {
    // Checks if the user is logged in. If so, update authState
    const checkAuthStatus = () => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          Auth.currentSession()
            .then((session) => {
              handleAccessTokenChange(session.accessToken);
            })
            .catch((error) => console.log("Error getting session: ", error));
          setAuthState({
            authStatus: authStatus.LOGGED_IN,
            user: user,
          });
        })
        .catch((e) => {
          setAuthState({
            authStatus: authStatus.NOT_LOGGED_IN,
            user: undefined,
          });
        });
    };

    checkAuthStatus();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {(() => {
              switch (authState.authStatus) {
                case authStatus.LOGGED_IN:
                  return (
                    <Dashboard
                      setAuthState={setAuthState}
                      authState={authState}
                      token={accessToken}
                      refreshing={refreshing}
                    />
                  );
                case authStatus.NOT_LOGGED_IN:
                  return (
                    <SignIn
                      setAuthState={setAuthState}
                      setAccessToken={handleAccessTokenChange}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </LocalizationProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
