import { Auth, API } from "aws-amplify";

const tempPassword = (first, last) => {
  return first[0] + last + `${Math.floor(1000 + Math.random() * 9000)}!`;
};

export const signUp = async (email, firstName, lastName, groupname) => {
  const apiName = "AdminQueries";
  const path = "/createUser";
  const myInit = {
    body: {
      username: email,
      password: tempPassword(firstName, lastName),
      given_name: firstName,
      family_name: lastName,
      groupname: groupname,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return await API.post(apiName, path, myInit);
};

export const addUserToGroup = async (email, groupname) => {
  const apiName = "AdminQueries";
  const path = "/addUserToGroup";
  const myInit = {
    body: {
      username: email,
      groupname: groupname,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return await API.post(apiName, path, myInit);
};

export const deleteUser = async (username) => {
  const apiName = "AdminQueries";
  const path = "/deleteUser";
  const myInit = {
    body: {
      username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  return await API.post(apiName, path, myInit);
};

export const getUsersWithGroups = async (limit) => {
  const response = await getAllUsers(limit);
  const users = response.Users;
  for (const user of users) {
    const group = await getUserGroup(user.Username);
    user.Groups = group.Groups;
  }
  return users;
};

const getUserGroup = async (username) => {
  const apiName = "AdminQueries";
  let path = "/listGroupsForUser";
  const myInit = {
    queryStringParameters: {
      username: username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  return rest;
};

// TODO: Implement next token for pagination
// let nextToken;
const getAllUsers = async (limit) => {
  const apiName = "AdminQueries";
  let path = "/listUsers";
  const myInit = {
    queryStringParameters: {
      limit: limit,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  // const nextToken = NextToken;
  return rest;
};
