import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { UserMngmentTableState } from "../Visualizations/Tables/tableState";

export const DeleteUserDialog = ({
  open,
  onClose,
  deleteUsers,
  tableState,
}) => {
  const userOrParticipantText =
    tableState === UserMngmentTableState.DASHBOARD ? "users" : "participants";

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {`Are you sure you want to delete selected ${userOrParticipantText}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting {userOrParticipantText} is a destructive action. You will
            not be able to recover {userOrParticipantText} once deleted.
            {tableState === UserMngmentTableState.PARTICIPANTS
              ? ' Also ensure the participants being deleted are aware they will need to delete their reminders by saying "Alexa, delete my reminders".'
              : ""}
          </DialogContentText>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={deleteUsers} color="primary">
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
