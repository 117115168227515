import React from "react";
import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import { MetricCard } from "./MetricCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(() => ({
  avatar: {
    width: "58px",
    backgroundColor: "#eb1c24",
    height: "58px",
  },
  textItem: {
    textAlign: "left",
  },
}));

export const Metric = ({
  data,
  processData,
  metricLabel,
  icon,
  handleTableOpen = null,
}) => {
  const classes = useStyles();

  return (
    <>
      <MetricCard handleTableOpen={handleTableOpen}>
        <Grid item className={classes.textItem}>
          <Typography color="textSecondary" gutterBottom variant="h6">
            {metricLabel}
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {processData(data)}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar className={classes.avatar}>
            <FontAwesomeIcon icon={icon} size="lg" />
          </Avatar>
        </Grid>
      </MetricCard>
    </>
  );
};
