import React, { useEffect, useState } from "react";
import "./UsersCard.css";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { UserMngmentTableState } from "../Visualizations/Tables/tableState";

const useStyles = makeStyles({
  table: {
    border: "none",
  },
});

export const UsersTable = (props) => {
  const classes = useStyles();
  const [cols, setCols] = useState([]);

  useEffect(() => {
    let cols = [];
    if (props.tableState === UserMngmentTableState.DASHBOARD) {
      cols = [
        { field: "email", headerName: "Email", flex: 1 },
        { field: "createdOn", headerName: "Created", flex: 1 },
        { field: "groups", headerName: "Groups", flex: 1 },
      ];
    } else {
      cols = [
        { field: "cohortid", headerName: "Cohort ID", width: 200 },
        { field: "username", headerName: "Alexa ID", width: 200 },
        { field: "firstname", headerName: "First Name", width: 200 },
        { field: "lastname", headerName: "Last Name", width: 200 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "homeVisitDate", headerName: "Home Visit Date", width: 200 },
        { field: "hasAlexa", headerName: "Has Alexa", width: 200 },
        { field: "hasAccount", headerName: "Uses Own Account", width: 220 },
      ];
    }

    setCols(cols);
  }, [props.tableState]);

  return (
    <>
      <div className="users-table-container">
        <DataGrid
          rows={props.rows}
          columns={cols}
          loading={props.loading}
          onSelectionModelChange={(newSelection) => {
            props.setSelectionModel(newSelection);
          }}
          selectionModel={props.selectionModel}
          checkboxSelection
          className={classes.table}
        />
      </div>
    </>
  );
};
