import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Box,
} from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tableState } from "./tableState";
import { SymptomsDataGrid } from "../DataGrids/SymptomsDataGrid";
import { DepressionScoreDataGrid } from "../DataGrids/DepressionScoreDataGrid";
import { CovidTableAccordian } from "../DataGrids/CovidTable/CovidTableAccordian";
import { StudySupportDataGrid } from "../DataGrids/StudySupportDataGrid";
import { ActivityDataGrid } from "../DataGrids/ActivityDataGrid";
import { ParticipantsDataGrid } from "../DataGrids/ParticipantsDataGrid";
import { MoodSupportDataGrid } from "../DataGrids/MoodSupportDataGrid";
import { ExpectedActualDataGrid } from "../DataGrids/ExpectedActualDataGrid";

const useStyles = makeStyles((theme) => ({
  table: {
    height: "75vh",
    width: "100%",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));

const TableDialog = ({ open, onClose, tblState, data, token, group }) => {
  const classes = useStyles();

  const Table = () => {
    if (tblState === tableState.STUDY_SUPPORT) {
      return (
        <StudySupportDataGrid
          data={data}
          token={token.jwtToken}
          group={group}
        />
      );
    } else if (tblState === tableState.SYMPTOM) {
      return <SymptomsDataGrid data={data} group={group} />;
    } else if (tblState === tableState.COVID19) {
      return <CovidTableAccordian data={data} group={group} />;
    } else if (tblState === tableState.DEPRESSION) {
      return <DepressionScoreDataGrid data={data} group={group} />;
    } else if (tblState === tableState.ACTIVITY) {
      return <ActivityDataGrid data={data} group={group} />;
    } else if (tblState === tableState.USERS) {
      return <ParticipantsDataGrid data={data} group={group} />;
    } else if (tblState === tableState.MOOD_SUPPORT) {
      return (
        <MoodSupportDataGrid data={data} token={token.jwtToken} group={group} />
      );
    } else if (tblState === tableState.EXPECTED_ACTUAL) {
      return (
        <ExpectedActualDataGrid
          users={data.participants}
          checkins={data.checkins}
          group={group}
        />
      );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
        <DialogTitle className={classes.root}>
          {tblState}
          <IconButton className={classes.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box className={classes.table}>
            <Table />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableDialog;
