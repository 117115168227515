import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import DatePicker from "@mui/lab/DatePicker";
import { useToasts } from "react-toast-notifications";
import { validateForm, noErrors } from "./formValidation";
import axios from "axios";

const useStyles = makeStyles({
  radioButtons: {
    paddingLeft: "5px",
  },
});

export const RegisterParticipantDialog = ({
  open,
  onClose,
  refreshTable,
  tableState,
  token,
}) => {
  const { REACT_APP_DB_ENDPOINT } = process.env;
  const classes = useStyles();
  const { addToast } = useToasts();
  const [newParticipant, setNewParticipant] = useState({
    email: "",
    firstName: "",
    lastName: "",
    hvDate: null,
    hasAccount: "",
    hasAlexa: "",
    cohortId: "",
  });

  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    hvDate: "",
    hasAccount: "",
    hasAlexa: "",
    cohortId: "",
  });

  const resetNewParticipant = () => {
    setNewParticipant({
      email: "",
      firstName: "",
      lastName: "",
      hvDate: null,
      hasAccount: "",
      hasAlexa: "",
      cohortId: "",
    });
  };

  const resetError = () => {
    setError({
      email: "",
      firstName: "",
      lastName: "",
      hvDate: "",
      hasAccount: "",
      hasAlexa: "",
      cohortId: "",
    });
  };

  const renderInput = (props) => (
    <TextField variant="outlined" fullWidth {...props} />
  );

  const handleTextChange = (e) => {
    const participantCopy = { ...newParticipant };
    if (e === null) {
      participantCopy.hvDate = "";
      return;
    }

    if (e instanceof Date) {
      participantCopy.hvDate = e;
    } else {
      const field = e.target.name;
      participantCopy[field] = e.target.value;
    }
    setNewParticipant(participantCopy);
  };

  const handleAddParticipant = async () => {
    let errors = validateForm(newParticipant);
    setError(errors);
    if (noErrors(errors)) {
      const accessToken = token.jwtToken;
      axios
        .put(`${REACT_APP_DB_ENDPOINT}/api/participants`, newParticipant, {
          headers: {
            accessToken: accessToken,
          },
        })
        .then((res) => {
          refreshTable(tableState);
          addToast(`Successfully created participant`, {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.message === "Not a valid JWT token"
          ) {
            return;
          }
          console.log("Error fetching total users:", err);
        });
      onClose();
      setError(errors);
      resetNewParticipant();
    } else {
      console.log("Form errors!");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          resetError();
          resetNewParticipant();
          onClose();
        }}
      >
        <DialogTitle>Register a Participant</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <DialogContentText>
            Fill in the information for the participant that you would like to
            register. First name, last name, and email are required.
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            name="firstName"
            error={error.firstName !== ""}
            helperText={error.firstName}
            value={newParticipant.firstName}
            onChange={handleTextChange}
            label="First Name"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            name="lastName"
            error={error.lastName !== ""}
            value={newParticipant.lastName}
            helperText={error.lastName}
            onChange={handleTextChange}
            label="Last Name"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            error={error.cohortId !== ""}
            name="cohortId"
            label="Cohort ID"
            helperText={error.cohortId}
            onChange={handleTextChange}
            value={newParticipant.cohortId}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <DatePicker
            fullWidth
            label="Home Visit Date"
            value={newParticipant.hvDate}
            onChange={handleTextChange}
            renderInput={renderInput}
          />
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <Box display="flex">
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.radioButtons}
            >
              <FormLabel component="legend">
                Already owns an Alexa device?
              </FormLabel>
              <RadioGroup
                row
                defaultValue="No"
                name="hasAlexa"
                onChange={handleTextChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.radioButtons}
            >
              <FormLabel component="legend">
                Will use an existing Alexa account?
              </FormLabel>
              <RadioGroup
                row
                defaultValue="No"
                name="hasAccount"
                onChange={handleTextChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogContent style={{ overflow: "visible" }}>
          <TextField
            error={error.email !== ""}
            name="email"
            label={
              newParticipant.hasAccount === "1"
                ? "Existing Account Email"
                : "Enter Study Email"
            }
            helperText={error.email}
            onChange={handleTextChange}
            value={newParticipant.email}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              resetError();
              resetNewParticipant();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleAddParticipant}>
            Register
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
