import { DataGrid } from "@material-ui/data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const SymptomsDataGrid = ({ data, group }) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const formatColumns = () => {
      const newColumns = [
        (group.includes("admin") || group.includes("super")) && {
          field: "cohortid",
          headerName: "Cohort ID",
          width: 130,
        },
        group.includes("admin") &&
          group.length === 1 && {
            field: "firstname",
            headerName: "First Name",
            width: 150,
          },
        group.includes("admin") &&
          group.length === 1 && {
            field: "lastname",
            headerName: "Last Name",
            width: 150,
          },
        { field: "checkinid", headerName: "Check-in ID", width: 160 },
        { field: "timestamp", headerName: "Date of Check-in", width: 220 },
        { field: "symptomatic", headerName: "Symptomatic", width: 170 },
        { field: "body_aches", headerName: "Body Aches", width: 160 },
        { field: "chest_pain", headerName: "Chest Pain", width: 150 },
        { field: "confusion", headerName: "Confusion", width: 150 },
        { field: "cough", headerName: "Cough", width: 130 },
        {
          field: "digestive_problems",
          headerName: "Digestive Problems",
          width: 220,
        },
        { field: "fatigued", headerName: "Fatigued", width: 140 },
        { field: "fever", headerName: "Fever", width: 120 },
        { field: "headache", headerName: "Headache", width: 150 },
        {
          field: "lightheadedness_fainting",
          headerName: "Lightheaded/Fainting",
          width: 220,
        },
        { field: "racing_heart", headerName: "Racing Heart", width: 170 },
        {
          field: "trouble_breathing",
          headerName: "Trouble Breathing",
          width: 200,
        },
        {
          field: "trouble_exercizing",
          headerName: "Trouble Exercizing",
          width: 200,
        },
        {
          field: "trouble_paying_attention",
          headerName: "Trouble Paying Attention",
          width: 250,
        },
        {
          field: "trouble_sleeping",
          headerName: "Trouble Sleeping",
          width: 200,
        },
      ];

      setColumns(newColumns);
    };

    const formatData = () => {
      const dataRows = [];
      for (const [key, value] of Object.entries(data)) {
        const entry = { ...value };
        entry.id = key;
        dataRows.push(entry);
      }
      setRows(dataRows);
    };

    formatColumns();
    formatData();
  }, [data, group]);

  return <DataGrid columns={columns} rows={rows} />;
};
