import axios from "axios";

export const axiosFetch = (accessToken, path) => {
  const { REACT_APP_DB_ENDPOINT } = process.env;
  return axios.get(`${REACT_APP_DB_ENDPOINT}/api/${path}`, {
    headers: {
      accessToken: accessToken,
    },
  });
};
