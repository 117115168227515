import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { contactAlertCodes } from "../Chart/contactAlertCodes";
import { makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles(() => ({
  root: {
    "& .red": {
      backgroundColor: "rgba(255, 99, 112, 0.4)",
    },
    "& .green": {
      backgroundColor: "rgba(95, 192, 192, 0.4)",
    },
  },
  dropdown: {
    width: "100%",
  },
}));

const renderDropdownCell = (params) => {
  return <Typography>{params.value ? "Resolved" : "Pending"}</Typography>;
};

const DropdownEditInputCell = (props) => {
  const { id, value, api, field, setLoading, token } = props;
  const classes = useStyles();
  const { REACT_APP_DB_ENDPOINT } = process.env;

  const handleChange = (event) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);

    if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
      setLoading(true);

      const data = {
        field: event.target.name,
        checkinid: props.row.checkinid,
        value: event.target.value,
        type: Object.keys(contactAlertCodes).find(
          (k) => contactAlertCodes[k] === props.row.type
        ),
      };

      axios
        .post(`${REACT_APP_DB_ENDPOINT}/api/studySupport`, data, {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            accessToken: token,
          },
        })
        .then(() => {
          setLoading(false);
          api.commitCellChange({ id, field });
          api.setCellMode(id, field, "view");
        })
        .catch((err) => {
          console.log(
            "Error fetching total users:",
            err.response !== undefined ? err.response.data : err
          );
        });
    }
  };

  const handleRef = (element) => {
    if (element) {
      element.querySelector(`input[value="${String(value)}"]`).focus();
    }
  };

  return (
    <Select
      ref={handleRef}
      name="resolved"
      value={value}
      onChange={handleChange}
      className={classes.dropdown}
    >
      <MenuItem value={false}>Pending</MenuItem>
      <MenuItem value={true}>Resolved</MenuItem>
    </Select>
  );
};

const renderDropdownEditInputCell = (params, setLoading, token) => {
  return (
    <DropdownEditInputCell {...params} setLoading={setLoading} token={token} />
  );
};

export const StudySupportDataGrid = ({ data, token, group }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const formatColumns = () => {
      const newColumns = [
        (group.includes("admin") || group.includes("super")) && {
          field: "cohortid",
          headerName: "Cohort ID",
          width: 130,
        },
        group.includes("admin") &&
          group.length === 1 && {
            field: "firstname",
            headerName: "First Name",
            width: 150,
          },
        group.includes("admin") &&
          group.length === 1 && {
            field: "lastname",
            headerName: "Last Name",
            width: 150,
          },
        { field: "checkinid", headerName: "Check-in ID", width: 160 },
        { field: "timestamp", headerName: "Date of Check-in", width: 220 },
        {
          field: "days_since_request",
          headerName: "Days Since Requested",
          width: 230,
        },
        {
          field: "resolved",
          headerName: "Resolved",
          renderCell: renderDropdownCell,
          renderEditCell: (params) =>
            renderDropdownEditInputCell(params, setLoading, token),
          editable: group.includes("regular") ? false : true,
          width: 170,
          type: "boolean",
        },
        { field: "type", headerName: "Support Type", width: 190 },
      ];

      setColumns(newColumns);
    };

    const formatData = () => {
      const dataRows = [];
      for (const [key, value] of Object.entries(data)) {
        if (value.type === 12) {
          continue;
        }

        if (value.type < 5 || value.type > 8) {
          const entry = { ...value };
          entry.id = key;

          const requestDate = new Date(value.timestamp);
          const daysSinceRequest =
            (Date.now() - requestDate.getTime()) / (1000 * 3600 * 24);
          entry.days_since_request = Math.floor(daysSinceRequest);
          entry.type = contactAlertCodes[value.type];

          dataRows.push(entry);
        }
      }
      setRows(dataRows);
    };

    formatColumns();
    formatData();
  }, [data, token, group]);

  return (
    <>
      <DataGrid
        columns={columns}
        rows={rows}
        className={classes.root}
        loading={loading}
        getCellClassName={(params) => {
          if (params.field === "resolved") {
            if (params.value) {
              return "green";
            } else {
              return "red";
            }
          } else {
            return "";
          }
        }}
      />
    </>
  );
};
