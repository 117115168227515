import React from "react";
import {
  CardContent,
  Grid,
  makeStyles,
  Card,
  Divider,
  Button,
  Box,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "15px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
    display: "block",
    padding: "0px",
    height: "100%",
  },
  avatar: {
    width: "58px",
    backgroundColor: "#eb1c24",
    height: "58px",
  },
  content: {
    paddingTop: "12px",
  },
  textItem: {
    textAlign: "left",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
}));

export const MetricCard = ({ children, handleTableOpen = null }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} justifyContent="space-between">
            {children}
          </Grid>
        </CardContent>
        {handleTableOpen && <Divider />}
        {handleTableOpen && (
          <Box className={classes.cardFooter}>
            <Button
              color="primary"
              size="small"
              variant="text"
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={handleTableOpen}
            >
              View Table
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};
