export const validateForm = (newParticipant) => {
  const errors = {
    email: "",
    firstName: "",
    lastName: "",
  };

  if (newParticipant.email === "") {
    errors.email = "Email is required";
  }
  if (newParticipant.firstName === "") {
    errors.firstName = "First name is required";
  }
  if (newParticipant.lastName === "") {
    errors.lastName = "Last name is required";
  }

  return errors;
};

export const noErrors = (errors) => {
  if (
    errors.email === "" &&
    errors.firstName === "" &&
    errors.lastname === ""
  ) {
    return false;
  }

  return true;
};
