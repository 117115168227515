export const contactAlertCodes = {
  1: "Failed set check-in time",
  2: "Failed set reminders", // User specifies they dont want the reminders set up
  3: "Prior Hospitalization",
  4: "Hospitalization",
  5: "Scored > 5 PHQ-8", // ignore
  6: "Scored > 10 PHQ-8",
  7: "Scored > 20 PHQ-8",
  8: "Asked for mood help",
  9: "UserPool API Problem", // ignore
  10: "Can't find user in database", // ignore
  11: "Problem with health question", // Failure to get permission to ask about symptoms
};
