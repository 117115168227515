import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export const ResetUserDialog = ({ open, onClose, resetUsers }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {"Are you sure you want to reset the selected participants?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Resetting a participant will erase their current progress within the
            ARIC study.
          </DialogContentText>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={resetUsers} color="primary">
              Reset
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
