import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import "./DashboardGrid.css";
import {
  faBell,
  faSmile,
  faUsers,
  faVirus,
} from "@fortawesome/free-solid-svg-icons";
import TableDialog from "../Visualizations/Tables/TableDialog";
import { tableState } from "../Visualizations/Tables/tableState";
import { axiosFetch } from "./dashboardQueries";
import { Metric } from "../Visualizations/Metric/Metric";
import {
  getTotalCovidReports,
  getTotalHighPHQ8Scores,
  getTotalReminders,
  getTotalParticipants,
} from "../Visualizations/Metric/metricFunctions";
import { Chart } from "../Visualizations/Chart/Chart";
import { DashboardType } from "../Visualizations/Chart/dashboardType";
import {
  formatActivityConfig,
  formatCovidTotalsConfig,
  formatMoodScoresConfig,
  formatSymptomsConfig,
} from "../Visualizations/Chart/chartFormatFunctions";
import { Table } from "../Visualizations/Tables/Table";
import { ExpectedActualDataGrid } from "../Visualizations/DataGrids/ExpectedActualDataGrid";

export const DashboardGrid = ({ group, token, refreshing, setIsLoading }) => {
  const { REACT_APP_DB_ENDPOINT } = process.env;
  const [participants, setParticipants] = useState([]);
  const [activity, setActivity] = useState([]);
  const [checkins, setCheckins] = useState([]);
  const [depressionScores, setDepressionScores] = useState([]);
  const [covidTotals, setCovidTotal] = useState({});
  const [symptoms, setSymptoms] = useState([]);

  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [tblState, setTblState] = useState(tableState.STUDY_SUPPORT);
  const [tblData, setTblData] = useState([]);

  useEffect(() => {
    const queryDashboardData = async () => {
      const accessToken = token.jwtToken;
      try {
        const usersResult = await axiosFetch(accessToken, "users");
        const activityResult = await axiosFetch(accessToken, "activity");
        const depressionResult = await axiosFetch(
          accessToken,
          "depressionScores"
        );
        const checkinsResult = await axiosFetch(accessToken, "checkins");
        const covidTotalResult = await axiosFetch(accessToken, "covidTotal");
        const symptomsResult = await axiosFetch(accessToken, "symptoms");

        setParticipants(usersResult.data);
        setActivity(activityResult.data);
        setCheckins(checkinsResult.data);
        setDepressionScores(depressionResult.data);
        setDepressionScores(depressionResult.data);
        setCovidTotal(covidTotalResult.data);
        setSymptoms(symptomsResult.data);
      } catch (error) {
        console.log("Error querying for dashboard data", error);
      }
      setIsLoading(false);
    };

    if (!refreshing) {
      queryDashboardData();
    }
  }, [REACT_APP_DB_ENDPOINT, token, refreshing, setIsLoading]);

  const handleTableDialogClose = () => {
    setTableDialogOpen(false);
  };

  const handleTableDialogOpen = (newTableState) => {
    setTblState(newTableState);

    if (newTableState === tableState.SYMPTOM) {
      setTblData(symptoms);
    } else if (newTableState === tableState.COVID19) {
      setTblData(covidTotals);
    } else if (newTableState === tableState.DEPRESSION) {
      setTblData(depressionScores);
    } else if (newTableState === tableState.ACTIVITY) {
      setTblData({ participants, checkins });
    } else if (newTableState === tableState.USERS) {
      setTblData(participants);
    } else if (newTableState === tableState.EXPECTED_ACTUAL) {
      setTblData({ participants, checkins });
    }

    setTableDialogOpen(true);
  };

  return (
    <>
      <div className="dashboard-charts-container">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item xs={12}>
                <Metric
                  data={participants}
                  processData={getTotalParticipants}
                  metricLabel="Total Enrolled Participants"
                  icon={faUsers}
                  handleTableOpen={() =>
                    handleTableDialogOpen(tableState.USERS)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Metric
                  data={participants}
                  processData={getTotalReminders}
                  metricLabel="Total Participants Who Setup Reminders"
                  icon={faBell}
                />
              </Grid>
              <Grid item xs={12}>
                <Chart
                  data={{ checkins, participants }}
                  processData={formatActivityConfig}
                  chartType="doughnut"
                  dashboardType={DashboardType.ACTIVITY}
                  headerTitle="Participant Activity"
                  handleTableOpen={() =>
                    handleTableDialogOpen(tableState.ACTIVITY)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} xl={4}>
            <Table
              headerTitle="Expected vs. Actual # of Check-ins"
              handleTableOpen={() =>
                handleTableDialogOpen(tableState.EXPECTED_ACTUAL)
              }
            >
              <ExpectedActualDataGrid
                users={participants}
                checkins={checkins}
                group={group}
              />
            </Table>
          </Grid>

          <Grid item xs={12} sm={12} md={4} xl={2}>
            <Chart
              data={depressionScores}
              processData={formatMoodScoresConfig}
              dashboardType={DashboardType.ACTIVITY}
              headerTitle="PHQ8 Scores"
              handleTableOpen={() =>
                handleTableDialogOpen(tableState.DEPRESSION)
              }
            >
              <Metric
                data={depressionScores}
                processData={getTotalHighPHQ8Scores}
                metricLabel="PHQ8 Scores >= 10"
                icon={faSmile}
              />
            </Chart>
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <Chart
              data={symptoms}
              processData={formatSymptomsConfig}
              dashboardType={DashboardType.ACTIVITY}
              headerTitle="Symptoms"
              handleTableOpen={() => handleTableDialogOpen(tableState.SYMPTOM)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <Chart
              data={covidTotals}
              processData={formatCovidTotalsConfig}
              chartType="doughnut"
              chartTitle="Self Reported vs. Diagnosed"
              dashboardType={DashboardType.ACTIVITY}
              headerTitle="COVID-19"
              handleTableOpen={() => handleTableDialogOpen(tableState.COVID19)}
            >
              <Metric
                data={covidTotals}
                processData={getTotalCovidReports}
                metricLabel="Total COVID-19 Reports"
                icon={faVirus}
              />
            </Chart>
          </Grid>
        </Grid>
        <TableDialog
          open={tableDialogOpen}
          onClose={handleTableDialogClose}
          tblState={tblState}
          data={tblData}
          token={token}
          group={group}
        />
      </div>
    </>
  );
};
