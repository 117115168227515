import React, { useState, useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import { Auth } from "aws-amplify";
import "./Dashboard.css";
import UsersCard from "./DashboardCards/UsersCard/UsersCard";
import { DashboardCard } from "./DashboardCards/DashboardCard/DashboardCard";
import { Toolbar } from "@material-ui/core";
import { SupportCard } from "./DashboardCards/SupportCard/SupportCard";

export const dashboardState = {
  DASHBOARD: "dashboard",
  SUPPORT: "support",
  USERS: "users",
};

const Dashboard = (props) => {
  const [group, setGroup] = useState([]);
  const [dashState, setDashState] = useState(dashboardState.DASHBOARD);

  // Check user group
  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        const groups = session.getIdToken().payload["cognito:groups"];
        if (groups !== undefined) {
          setGroup(groups);
        }
      })
      .catch((error) => console.log("Error getting session: ", error));
  }, []);

  return (
    <>
      <div className="dashboard-card">
        <Navbar
          setAuthState={props.setAuthState}
          authStatus={props.authStatus}
          setDashState={setDashState}
          dashState={dashState}
          group={group}
        />
        <Toolbar />
        {(() => {
          switch (dashState) {
            case dashboardState.DASHBOARD:
              return (
                <DashboardCard
                  group={group}
                  token={props.token}
                  refreshing={props.refreshing}
                />
              );
            case dashboardState.USERS:
              return (
                <UsersCard
                  group={group}
                  dashState={dashState}
                  token={props.token}
                  refreshing={props.refreshing}
                />
              );
            case dashboardState.SUPPORT:
              return (
                <SupportCard
                  token={props.token}
                  refreshing={props.refreshing}
                  group={group}
                />
              );
            default:
              return;
          }
        })()}
      </div>
    </>
  );
};

export default Dashboard;
