import React, { useState } from "react";
import "../DashboardCard/DashboardCard.css";
import { SupportGrid } from "./SupportGrid";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loadingMessage: {
    color: "#ffff",
    fontWeight: "600",
  },
}));

export const SupportCard = ({ token, refreshing, group }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="dashboard-card-inner">
      <SupportGrid
        token={token}
        refreshing={refreshing}
        setIsLoading={setIsLoading}
        group={group}
      />
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};
