import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import "./DashboardCard.css";
import { DashboardGrid } from "./DashboardGrid";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  loadingMessage: {
    color: "#ffff",
    fontWeight: "600",
  },
}));

export const DashboardCard = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div className="dashboard-card-inner">
        <DashboardGrid
          group={props.group}
          token={props.token}
          refreshing={props.refreshing}
          setIsLoading={setIsLoading}
        />
        <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    </>
  );
};
