import React from "react";
import {
  Box,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  Card,
  Divider,
  Button,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "15px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
    display: "block",
    padding: "0px",
  },
  header: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px",
    textAlign: "start",
    marginLeft: "10px",
  },
  content: {
    height: "325px",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
}));

export const ChartCard = ({
  headerTitle,
  dashboardType,
  handleTableOpen,
  children,
}) => {
  // Change the height of the chart card based on what type of chart it is

  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <Typography className={classes.header}>{headerTitle}</Typography>
        <Divider />
        <CardContent className={classes.content}>
          <Grid container spacing={3} direction="column">
            {children}
          </Grid>
        </CardContent>
        {handleTableOpen && <Divider />}
        {handleTableOpen && (
          <Box className={classes.cardFooter}>
            <Button
              color="primary"
              size="small"
              variant="text"
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={handleTableOpen}
            >
              View Table
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};
