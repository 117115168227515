import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .red": {
      backgroundColor: "rgba(255, 99, 112, 0.4)",
    },
    "& .yellow": {
      backgroundColor: "rgba(255, 206, 86, 0.4)",
    },
    "& .green": {
      backgroundColor: "rgba(95, 192, 192, 0.4)",
    },
  },
}));

export const ExpectedActualDataGrid = ({ users, checkins, group }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const getExpectedNumOfCheckins = (daysSinceStart) => {
      if (daysSinceStart < 5) {
        return daysSinceStart;
      } else {
        const daysNoStartDates = daysSinceStart - 4;
        const actualNumOfCheckins = Math.floor(daysNoStartDates / 7);
        return actualNumOfCheckins + 5;
      }
    };
    const expectedActual = [];

    for (const [key, value] of Object.entries(users)) {
      let expectedActualEntry = {
        id: key,
        cohortid: value.cohortid,
        firstname: value.firstname,
        lastname: value.lastname,
        latestCheckin: null,
        actual: 0,
        expected: 0,
        percentCompleted: "0%",
      };

      if (value.datestart !== null) {
        if (checkins.length !== 0) {
          const startDate = new Date(value.datestart);
          const daysSinceStartDay = Math.floor(
            (Date.now() - startDate.getTime()) / (1000 * 3600 * 24)
          );
          const userCheckins = checkins.filter((checkin) => {
            return checkin.userid === value.userid;
          });

          const mostRecentCheckin = userCheckins.reduce((a, b) => {
            return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
          });

          const latestCheckinDate = new Date(mostRecentCheckin.timestamp);
          expectedActualEntry.latestCheckin = latestCheckinDate.toDateString();
          expectedActualEntry.actual = userCheckins.length;

          expectedActualEntry.expected =
            getExpectedNumOfCheckins(daysSinceStartDay);

          expectedActualEntry.percentCompleted =
            Math.floor(
              (expectedActualEntry.actual / expectedActualEntry.expected) * 100
            ) + "%";
        }
      }

      expectedActual.push(expectedActualEntry);
    }

    setRows(expectedActual);
  }, [users, checkins]);

  const cols = [
    (group.includes("admin") || group.includes("super")) && {
      field: "cohortid",
      headerName: "Cohort ID",
      width: 150,
    },
    group.includes("admin") &&
      group.length === 1 && {
        field: "firstname",
        headerName: "First Name",
        width: 150,
      },
    group.includes("admin") &&
      group.length === 1 && {
        field: "lastname",
        headerName: "Last Name",
        width: 150,
      },
    { field: "latestCheckin", headerName: "Latest Check-in", width: 200 },
    { field: "actual", headerName: "Actual", width: 140 },
    { field: "expected", headerName: "Expected", width: 140 },
    {
      field: "percentCompleted",
      headerName: "% Actual vs. Expected",
      width: 230,
    },
  ];

  return (
    <DataGrid
      columns={cols}
      rows={rows}
      className={classes.root}
      getCellClassName={(params) => {
        if (params.field === "percentCompleted") {
          const percent = parseFloat(params.value);
          if (percent >= 0 && percent <= 50) {
            return "red";
          } else if (percent >= 51 && params.value <= 99) {
            return "yellow";
          } else {
            return "green";
          }
        } else {
          return "";
        }
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "actual", sort: "desc" }],
        },
      }}
    />
  );
};
