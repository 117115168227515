export const tableState = {
  STUDY_SUPPORT: "Study Support Table",
  COVID19: "COVID-19 Table",
  SYMPTOM: "Symptoms Table",
  DEPRESSION: "Depression Scores Table",
  ACTIVITY: "Participant Activity Table",
  USERS: "Participants Table",
  MOOD_SUPPORT: "Mood Support Table",
  EXPECTED_ACTUAL: "Expected vs. Actual # of Check-ins",
};

export const UserMngmentTableState = {
  DASHBOARD: "Dashboard Users",
  PARTICIPANTS: "Study Participants",
};
// export const tableState;
