export const validationErrorMessage = {
  EMPTY_EMAIL_OR_PASS: "Email or password fields are empty.",
  EMPTY_RESET_PASS: "A password field is empty.",
  EMPTY_CODE_OR_PASS: "Empty verification code or password field.",
  CANNOT_REUSE_CURR_PASS: "Current password cannot be used.",
  PASS_NOT_MATCHING: "Passwords do not matching.",
};

export const signInState = {
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
};

// export const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^$*.[]{}()?-“!@#%&/,><’:;|_~`])S{8,99}$');
