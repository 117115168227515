import { contactAlertCodes } from "./contactAlertCodes";
export const formatSymptomsConfig = (symptoms) => {
  const symptomData = {
    Fever: 0,
    "Trouble Breathing": 0,
    Cough: 0,
    "Digestive Problems": 0,
    Headache: 0,
    "Body Aches": 0,
    "Chest Pain": 0,
    Fatigued: 0,
    "Trouble Paying Attention": 0,
    Confusion: 0,
    "Lightheadedness/Fainting": 0,
    "Racing Heart": 0,
    "Trouble Sleeping": 0,
    "Trouble Exercizing": 0,
  };

  if (symptoms !== undefined) {
    const filteredSymptoms = symptoms.filter((checkin) => checkin.symptomatic);

    for (const [, value] of Object.entries(filteredSymptoms)) {
      if (value.fever) {
        symptomData.Fever += 1;
      }
      if (value.trouble_breathing) {
        symptomData["Trouble Breathing"] += 1;
      }
      if (value.cough) {
        symptomData["Cough"] += 1;
      }
      if (value.digestive_problems) {
        symptomData["Digestive Problems"] += 1;
      }
      if (value.headache) {
        symptomData["Headache"] += 1;
      }
      if (value.body_aches) {
        symptomData["Body Aches"] += 1;
      }
      if (value.chest_pain) {
        symptomData["Chest Pain"] += 1;
      }
      if (value.fatigued) {
        symptomData["Fatigued"] += 1;
      }
      if (value.trouble_paying_attention) {
        symptomData["Trouble Paying Attention"] += 1;
      }
      if (value.confusion) {
        symptomData["Confusion"] += 1;
      }
      if (value.lightheadedness_fainting) {
        symptomData["Lightheadedness/Fainting"] += 1;
      }
      if (value.racing_heart) {
        symptomData["Racing Heart"] += 1;
      }
      if (value.trouble_sleeping) {
        symptomData["Trouble Sleeping"] += 1;
      }
      if (value.trouble_exercizing) {
        symptomData["Trouble Exercizing"] += 1;
      }
    }
  }

  const configuration = {
    labels: Object.keys(symptomData),
    datasets: [
      {
        label: "# of Participants with symptom",
        data: Object.values(symptomData),
        backgroundColor: ["rgba(95, 192, 192, 0.5)"],
      },
    ],
  };

  return configuration;
};

export const formatActivityConfig = ({ checkins, participants }) => {
  const activityData = {
    "Short term inactive (between 7 - 14 days)": 0,
    "Long term inactive (14 days or over)": 0,
    "Inactive (never checked in)": 0,
    "Active (less than 7 days)": 0,
  };

  // TODO: This is inefficient. Must change.
  for (const [, value] of Object.entries(participants)) {
    const userCheckins = checkins.filter((checkin) => {
      return checkin.userid === value.userid;
    });

    if (userCheckins.length === 0) {
      activityData["Inactive (never checked in)"] += 1;
      continue;
    }

    const mostRecentCheckin = userCheckins.reduce((a, b) => {
      return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
    });

    const checkinTimestamp = new Date(mostRecentCheckin.timestamp);
    const daysSincelastCheckin = Math.floor(
      (Date.now() - checkinTimestamp.getTime()) / (1000 * 3600 * 24)
    );

    if (daysSincelastCheckin >= 7 && daysSincelastCheckin < 14) {
      activityData["Short term inactive (between 7 - 14 days)"] += 1;
    } else if (daysSincelastCheckin > 14) {
      activityData["Long term inactive (14 days or over)"] += 1;
    } else {
      activityData["Active (less than 7 days)"] += 1;
    }
  }

  const configuration = {
    labels: Object.keys(activityData),
    datasets: [
      {
        data: Object.values(activityData),
        backgroundColor: [
          "rgba(255, 206, 86, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 99, 112, 0.5)",
          "rgba(95, 192, 192, 0.5)",
        ],
      },
    ],
  };

  return configuration;
};

export const formatMoodScoresConfig = (moodScores) => {
  const depressionRanges = {
    "0-4": 0,
    "5-9": 0,
    ">=10": 0,
  };

  for (const [, value] of Object.entries(moodScores)) {
    if (value.depression_score >= 0 && value.depression_score <= 4) {
      depressionRanges["0-4"] += 1;
    } else if (value.depression_score >= 4 && value.depression_score <= 9) {
      depressionRanges["5-9"] += 1;
    } else {
      depressionRanges[">=10"] += 1;
    }
  }

  const configuration = {
    labels: Object.keys(depressionRanges),
    datasets: [
      {
        label: "Participants within PHQ-8 score range",
        data: Object.values(depressionRanges),
        backgroundColor: [
          "rgba(95, 192, 192, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(255, 99, 112, 0.5)",
        ],
      },
    ],
  };

  return configuration;
};

export const formatCovidTotalsConfig = (covidTotals) => {
  const reported = {
    "Self-reported": 0,
    Diagnosed: 0,
  };

  let selfReported = 0;
  let diagnosed = 0;
  if (
    covidTotals["pastCovid"] !== undefined &&
    covidTotals["testedPos"] !== undefined
  ) {
    const usersAccountedFor = [];
    for (const [, value] of Object.entries(covidTotals.pastCovid)) {
      if (value.everPositive !== null) {
        if (value.everPositive) {
          if (!usersAccountedFor.includes(value.userid)) {
            diagnosed += 1;
            usersAccountedFor.push(value.userid);
          }
        } else {
          if (value.everCovid !== null) {
            if (value.everCovid) {
              if (!usersAccountedFor.includes(value.userid)) {
                selfReported += 1;
                usersAccountedFor.push(value.userid);
              }
            }
          }
        }
      }
    }

    for (const [, value] of Object.entries(covidTotals.testedPos)) {
      if (
        !usersAccountedFor.includes(value.userid) &&
        value.testedPositive !== null
      ) {
        if (value.testedPositive) {
          diagnosed += 1;
          usersAccountedFor.push(value.userid);
        }
      }
    }
  }

  reported["Self-reported"] = selfReported;
  reported["Diagnosed"] = diagnosed;

  const configuration = {
    labels: Object.keys(reported),
    datasets: [
      {
        data: Object.values(reported),
        backgroundColor: ["rgba(255, 206, 86, 0.5)", "rgba(95, 192, 192, 0.5)"],
      },
    ],
  };

  return configuration;
};

const pendingRequests = (reqs) => {
  const pendingReqs = [];
  for (const [, value] of Object.entries(reqs)) {
    pendingReqs.push(value.pending);
  }
  return pendingReqs;
};
const resolvedRequests = (reqs) => {
  const pendingReqs = [];
  for (const [, value] of Object.entries(reqs)) {
    pendingReqs.push(value.resolved);
  }
  return pendingReqs;
};
export const formatMoodSupportConfig = (studySupport) => {
  const data = {};

  for (const [, value] of Object.entries(studySupport)) {
    // Ignore contact alert code 12
    if (value.type === 12) {
      continue;
    }

    if (value.type >= 5 && value.type <= 8) {
      if (data[contactAlertCodes[value.type]] === undefined) {
        data[contactAlertCodes[value.type]] = {};
        data[contactAlertCodes[value.type]].pending = 0;
        data[contactAlertCodes[value.type]].resolved = 0;
      }

      if (value.resolved) {
        data[contactAlertCodes[value.type]].resolved += 1;
      } else {
        data[contactAlertCodes[value.type]].pending += 1;
      }
    }
  }

  const configuration = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "Resolved",
        data: resolvedRequests(data),
        backgroundColor: ["rgba(95, 192, 192, 0.5)"],
      },
      {
        label: "Pending",
        data: pendingRequests(data),
        backgroundColor: ["rgba(255, 99, 112, 0.5)"],
      },
    ],
  };

  return configuration;
};

export const formatStudySupport = (studySupport) => {
  const data = {};

  for (const [, value] of Object.entries(studySupport)) {
    // Ignore contact alert code 12
    if (value.type === 12) {
      continue;
    }

    if (value.type < 5 || value.type > 8) {
      if (data[contactAlertCodes[value.type]] === undefined) {
        data[contactAlertCodes[value.type]] = {};
        data[contactAlertCodes[value.type]].pending = 0;
        data[contactAlertCodes[value.type]].resolved = 0;
      }

      if (value.resolved) {
        data[contactAlertCodes[value.type]].resolved += 1;
      } else {
        data[contactAlertCodes[value.type]].pending += 1;
      }
    }
  }

  const configuration = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "Resolved",
        data: resolvedRequests(data),
        backgroundColor: ["rgba(95, 192, 192, 0.5)"],
      },
      {
        label: "Pending",
        data: pendingRequests(data),
        backgroundColor: ["rgba(255, 99, 112, 0.5)"],
      },
    ],
  };

  return configuration;
};
