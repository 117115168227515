import {
  faChartArea,
  faSignOutAlt,
  faUsers,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import logo from "../../../icons/full-logo.jpg";
import React from "react";
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  makeStyles,
  Button,
  Box,
} from "@material-ui/core";
import { authStatus } from "../../../authEnum";
import { dashboardState } from "../Dashboard";
import "./Navbar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  toolbarButton: {
    marginRight: theme.spacing(2),
    fontSize: 15,
    "&:hover": {
      background: "none",
    },
  },
  logo: {
    marginTop: "10px",
    marginRight: theme.spacing(2),
  },
}));

const Navbar = (props) => {
  const classes = useStyles();

  const signOut = () => {
    Auth.signOut();
    props.setAuthState({
      authStatus: authStatus.NOT_LOGGED_IN,
      user: undefined,
    });
  };

  const SupportButton = () => {
    return (
      <Button
        color="inherit"
        className={classes.toolbarButton}
        onClick={(e) => {
          props.setDashState(dashboardState.SUPPORT);
        }}
        disableRipple
      >
        <FontAwesomeIcon icon={faPhone} style={{ marginRight: "5px" }} />
        Support
      </Button>
    );
  };

  const UserManagementButton = () => {
    if (props.group.includes("admin") || props.group.includes("super")) {
      return (
        <Button
          color="inherit"
          className={classes.toolbarButton}
          onClick={(e) => {
            props.setDashState(dashboardState.USERS);
          }}
          disableRipple
        >
          <FontAwesomeIcon icon={faUsers} style={{ marginRight: "5px" }} />
          Management
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Box display={"flex"} flexGrow={1}>
              <Typography
                className={classes.logo}
                onClick={(e) => {
                  props.setDashState(dashboardState.DASHBOARD);
                }}
              >
                <img src={logo} alt="logo" className="logo" />
              </Typography>
              <Button
                color="inherit"
                className={classes.toolbarButton}
                onClick={(e) => {
                  props.setDashState(dashboardState.DASHBOARD);
                }}
                disableRipple
              >
                <FontAwesomeIcon
                  icon={faChartArea}
                  style={{ marginRight: "5px" }}
                />
                Dashboard
              </Button>
              <SupportButton />
              <UserManagementButton />
            </Box>
            <IconButton color="inherit" onClick={signOut}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default Navbar;
