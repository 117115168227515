import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .red": {
      backgroundColor: "rgba(255, 99, 112, 0.4)",
    },
    "& .yellow": {
      backgroundColor: "rgba(255, 206, 86, 0.4)",
    },
    "& .green": {
      backgroundColor: "rgba(95, 192, 192, 0.4)",
    },
  },
}));

export const DepressionScoreDataGrid = ({ data, group }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const depScores = [];

    for (const [key, value] of Object.entries(data)) {
      value.id = key;
      value.depression_score =
        value.depression_score !== null ? parseInt(value.depression_score) : 0;
      depScores.push(value);
    }

    setRows(depScores);
  }, [data]);

  const cols = [
    (group.includes("admin") || group.includes("super")) && {
      field: "cohortid",
      headerName: "Cohort ID",
      width: 130,
    },
    group.includes("admin") &&
      group.length === 1 && {
        field: "firstname",
        headerName: "First Name",
        width: 150,
      },
    group.includes("admin") &&
      group.length === 1 && {
        field: "lastname",
        headerName: "Last Name",
        width: 150,
      },
    { field: "checkinid", headerName: "Check-in ID", width: 170 },
    { field: "checkin_date", headerName: "Check-in Date", width: 220 },
    { field: "depression_score", headerName: "Depression Score", width: 200 },
  ];

  return (
    <DataGrid
      columns={cols}
      rows={rows}
      className={classes.root}
      getCellClassName={(params) => {
        if (params.field === "depression_score") {
          if (params.value >= 0 && params.value <= 4) {
            return "green";
          } else if (params.value >= 5 && params.value <= 9) {
            return "yellow";
          } else {
            return "red";
          }
        } else {
          return "";
        }
      }}
    />
  );
};
