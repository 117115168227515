import React from "react";
import { Typography, makeStyles, Switch, Box, Grid } from "@material-ui/core";
import { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  switch: {
    marginBottom: 10,
  },
  covidTable: {
    height: "68vh",
  },
  root: {
    height: "100%",
  },
}));

export const CovidTableAccordian = ({ data, group }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  const handleSwitch = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    const formatCols = () => {
      let newCols = [];

      if (!checked) {
        newCols = [
          (group.includes("admin") || group.includes("super")) && {
            field: "cohortid",
            headerName: "Cohort ID",
            width: 130,
          },
          group.includes("admin") &&
            group.length === 1 && {
              field: "firstname",
              headerName: "First Name",
              width: 150,
            },
          group.includes("admin") &&
            group.length === 1 && {
              field: "lastname",
              headerName: "Last Name",
              width: 150,
            },
          { field: "checkinid", headerName: "Check-in ID", width: 160 },
          { field: "timestamp", headerName: "Date of Check-in", width: 220 },
          { field: "everPositive", headerName: "Tested Positive", width: 180 },
          { field: "everCovid", headerName: "Undiagnosed COVID", width: 220 },
        ];
      } else {
        newCols = [
          (group.includes("admin") || group.includes("super")) && {
            field: "cohortid",
            headerName: "Cohort ID",
            width: 130,
          },
          group.includes("admin") &&
            group.length === 1 && {
              field: "firstname",
              headerName: "First Name",
              width: 150,
            },
          group.includes("admin") &&
            group.length === 1 && {
              field: "lastname",
              headerName: "Last Name",
              width: 150,
            },
          { field: "checkinid", headerName: "Check-in Id", width: 160 },
          { field: "timestamp", headerName: "Date of Check-in", width: 220 },
          {
            field: "testedpositive",
            headerName: "Tested Positive",
            width: 180,
          },
          {
            field: "positivedate",
            headerName: "Tested Positive Date",
            width: 220,
          },
          { field: "symptomatic", headerName: "Symptomatic", width: 170 },
          { field: "hospitalized", headerName: "Hospitalized", width: 170 },
        ];
      }

      setCols(newCols);
    };
    const formatData = () => {
      const dataRows = [];
      if (!checked) {
        for (const [key, value] of Object.entries(data["pastCovid"])) {
          const entry = { ...value };
          entry.id = key;

          dataRows.push(entry);
        }
      } else {
        for (const [key, value] of Object.entries(data["testedPos"])) {
          const entry = { ...value };
          entry.id = key;

          dataRows.push(entry);
        }
      }

      setRows(dataRows);
    };

    formatCols();
    formatData();
  }, [checked, data, group]);

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.switch}
          >
            <Grid item>
              <Typography variant="h6">Past COVID-19</Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={checked}
                name="covidSwitch"
                onChange={handleSwitch}
              ></Switch>
            </Grid>
            <Grid item>
              <Typography variant="h6">Current COVID-19</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box className={classes.covidTable}>
            <DataGrid rows={rows} columns={cols} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
