import React, { useState } from "react";
import logo from "../../icons/full-logo.jpg";
import "./SignIn.css";
import SignInForm from "./Forms/SignInForm";
import ResetPassForm from "./Forms/ResetPassForm";
import ForgotPassForm from "./Forms/ForgotPassForm";

export const signInState = {
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
};

// Sign in card component
const SignIn = (props) => {
  // toggle sign in state
  const [signInFormState, setSignInState] = useState(signInState.LOGIN);

  // User object to be passed to reset password function
  const [tempUser, setTempUser] = useState(null);

  // Email to provide the forgot password form
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="signin-card">
        <img src={logo} alt="logo" className="logo" />
        <h5
          className={
            signInFormState === signInState.LOGIN
              ? "reset-pass-header-white"
              : "reset-pass-header"
          }
        >
          Reset your password
        </h5>
        <div className="signin-form">
          {(() => {
            switch (signInFormState) {
              case signInState.LOGIN:
                return (
                  <SignInForm
                    setTempUser={setTempUser}
                    setAuthState={props.setAuthState}
                    setSignInState={setSignInState}
                    setEmail={setEmail}
                    setAccessToken={props.setAccessToken}
                  />
                );
              case signInState.RESET_PASSWORD:
                return (
                  <ResetPassForm
                    setAuthState={props.setAuthState}
                    tempUser={tempUser}
                    setAccessToken={props.setAccessToken}
                  />
                );
              case signInState.FORGOT_PASSWORD:
                return (
                  <ForgotPassForm
                    setAuthState={props.setAuthState}
                    setAccessToken={props.setAccessToken}
                    email={email}
                  />
                );
              default:
                return;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default SignIn;
