import React, { useState } from "react";
import { signInState } from "../validationEnums";
import "../SignIn.css";
import { validationErrorMessage } from "../validationEnums";
import { Auth } from "aws-amplify";
import { authStatus } from "../../../authEnum";
import { useToasts } from "react-toast-notifications";

const SignInForm = (props) => {
  const [errorMsg, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { addToast } = useToasts();

  // handle email text field change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // handle password text field change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // handle forgot password button click
  const handleForgotPassword = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  // handle login submit or button click
  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setErrorMessage(validationErrorMessage.EMPTY_EMAIL_OR_PASS);
      return;
    }

    signIn(email, password);
  };

  // Signs in user through AWS cognito
  const signIn = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);
      setErrorMessage("");
      // Toggle reset password screen
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        props.setTempUser(user);
        props.setSignInState(signInState.RESET_PASSWORD);
      }
      // User logged in -> update auth state
      else {
        Auth.currentAuthenticatedUser()
          .then(() => {
            Auth.currentSession()
              .then((session) => {
                props.setAccessToken(session.accessToken);
              })
              .catch((error) => console.log("Error getting session: ", error));
          })
          .catch((e) => {
            console.log("Error returning user session", e);
          });
        props.setAuthState({
          authStatus: authStatus.LOGGED_IN,
          user: user,
        });
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  // Submit request to aws cognito to send verification code to reset password
  const forgotPassword = (email) => {
    Auth.forgotPassword(email)
      .then((data) => {
        addToast("Forgot password verification code sent", {
          appearance: "success",
          autoDismiss: true,
        });
        props.setEmail(email);
        props.setSignInState(signInState.FORGOT_PASSWORD);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      <form>
        <input
          className="textfield"
          type="text"
          key="email"
          id="email"
          placeholder="Email"
          onChange={handleEmailChange}
        />
        <input
          className="textfield"
          type="password"
          key="password"
          id="password"
          placeholder="Password"
          onChange={handlePasswordChange}
        />
        <div className="error-message">{errorMsg}</div>
        <button
          className="login-button"
          id="login-button"
          type="submit"
          onClick={handleLogin}
        >
          Login
        </button>
        <button
          type="button"
          className="forgot-pass"
          id="forgot-pass"
          onClick={handleForgotPassword}
        >
          Forgot password?
        </button>
      </form>
    </>
  );
};

export default SignInForm;
