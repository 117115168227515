import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const ActivityDataGrid = ({ data, group }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const formatData = () => {
      const { participants, checkins } = data;
      const dataRows = [];
      for (const [key, value] of Object.entries(participants)) {
        console.log("data: ", data);
        let entry = {
          id: key,
          cohortid: value.cohortid,
          firstname: value.firstname,
          lastname: value.lastname,
          last_checkin: null,
          days_last_checkin: null,
        };

        const userCheckins = checkins.filter((checkin) => {
          return checkin.userid === value.userid;
        });

        if (userCheckins.length === 0) {
          dataRows.push(entry);
          continue;
        }

        const mostRecentCheckin = userCheckins.reduce((a, b) => {
          return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
        });

        const checkinTimestamp = new Date(mostRecentCheckin.timestamp);
        const daysSincelastCheckin = Math.floor(
          (Date.now() - checkinTimestamp.getTime()) / (1000 * 3600 * 24)
        );

        entry.last_checkin = checkinTimestamp.toDateString();
        entry.days_last_checkin = daysSincelastCheckin;
        dataRows.push(entry);
      }
      setRows(dataRows);
    };

    formatData();
  }, [data, group]);

  const columns = [
    (group.includes("admin") || group.includes("super")) && {
      field: "cohortid",
      headerName: "Cohort ID",
      width: 200,
    },
    group.includes("admin") &&
      group.length === 1 && {
        field: "firstname",
        headerName: "First Name",
        width: 200,
      },
    group.includes("admin") &&
      group.length === 1 && {
        field: "lastname",
        headerName: "Last Name",
        width: 200,
      },
    { field: "last_checkin", headerName: "Last Check-in", width: 220 },
    {
      field: "days_last_checkin",
      headerName: "Days Since Last Check-in",
      width: 250,
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      initialState={{
        sorting: { sortModel: [{ field: "days_last_checkin", sort: "asc" }] },
      }}
    />
  );
};
