import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { authStatus } from "../../../authEnum";
import { validationErrorMessage } from "../validationEnums";
import "../SignIn.css";

const ForgotPassForm = (props) => {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMsg, setErrorMessage] = useState("");

  // Handles verification code textfield
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  // Handles new password textfield
  const handleNewPassChange = (e) => {
    setNewPassword(e.target.value);
  };

  // Submits forgot pass to aws if text is valid. On button click or submit.
  const handleForgotPassSubmit = (e) => {
    e.preventDefault();

    // VALIDATION
    if (code === "" || newPassword === "") {
      setErrorMessage(validationErrorMessage.EMPTY_CODE_OR_PASS);
      return;
    }

    forgotPassSubmit(props.email, code, newPassword);
  };

  // Submits new pass to aws if code and email are valid
  const forgotPassSubmit = (email, code, newPass) => {
    Auth.forgotPasswordSubmit(email, code, newPass)
      .then((data) => {
        Auth.signIn(email, newPass)
          .then((user) => {
            Auth.currentAuthenticatedUser()
              .then(() => {
                Auth.currentSession()
                  .then((session) => {
                    props.setAccessToken(session.accessToken);
                  })
                  .catch((error) =>
                    console.log("Error getting session: ", error)
                  );
              })
              .catch((e) => {
                console.log("Error returning user session", e);
              });
            props.setAuthState({
              authStatus: authStatus.LOGGED_IN,
              user: user,
            });
          })
          .catch((error) => {
            console.log(
              "error signing in after successful forgot pass reset: ",
              error
            );
            setErrorMessage(error.message);
          });
      })
      .catch((error) => {
        console.log("after submit error: ", error);
        setErrorMessage(error.message);
      });
  };

  return (
    <>
      <form onSubmit={handleForgotPassSubmit}>
        <input
          className="textfield"
          type="text"
          id="verification-code"
          placeholder="Verification Code"
          onChange={handleCodeChange}
        />
        <input
          className="textfield"
          type="password"
          id="confirm-pass"
          placeholder="New Password"
          onChange={handleNewPassChange}
        />
        <div className="error-message">{errorMsg}</div>
        <button
          className="login-button"
          id="reset-pass"
          type="button"
          onClick={(e) => {
            handleForgotPassSubmit(e);
          }}
        >
          Reset Password
        </button>
      </form>
    </>
  );
};

export default ForgotPassForm;
