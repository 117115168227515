import {
  faSyncAlt,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./UsersCard.css";
import "./UserTableControls.css";
import { deleteUser } from "./userFunctions";
import { useToasts } from "react-toast-notifications";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { AddUserDialog } from "./AddUserDialog";
import { RegisterParticipantDialog } from "./RegisterParticipantDialog";
import { UserMngmentTableState } from "../Visualizations/Tables/tableState";
import axios from "axios";
import { ResetUserDialog } from "./ResetUserDialog";

export const UserTableControls = (props) => {
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [resetUserOpen, setResetUserOpen] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const { REACT_APP_DB_ENDPOINT } = process.env;
  const { addToast } = useToasts();

  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  const handleAddUserClose = () => {
    setAddUserOpen(false);
  };

  const handleDeleteUserOpen = () => {
    setDeleteUserOpen(true);
  };

  const handleDeleteUserClose = () => {
    setDeleteUserOpen(false);
  };

  const handleResetUserOpen = () => {
    setResetUserOpen(true);
  };

  const handleResetUserClose = () => {
    setResetUserOpen(false);
  };

  const handleDeleteUsers = async (e) => {
    handleDeleteUserClose();
    if (props.tableState === UserMngmentTableState.DASHBOARD) {
      try {
        for (let i in props.selectionModel) {
          const username = props.selectionModel[i];
          await deleteUser(username);
        }
        await props.refreshTable(props.tableState);
        addToast(
          `Successfully deleted (${props.selectionModel.length}) User${
            props.selectionModel.length > 1 ? "s" : ""
          }`,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      } catch (error) {
        addToast("Unable to delete selected Users", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log("error: ", error);
      }
    } else {
      for (let i in props.selectionModel) {
        const username = props.selectionModel[i];
        const accessToken = props.token.jwtToken;
        const body = { username: username };
        axios
          .delete(`${REACT_APP_DB_ENDPOINT}/api/participants`, {
            data: body,
            headers: {
              accessToken: accessToken,
            },
          })
          .then(() => {
            try {
              props.refreshTable(props.tableState);
            } catch (error) {
              console.log("Error refreshing table after participant delete");
            }
          })
          .catch((err) => {
            if (
              err.response !== undefined &&
              err.response.message === "Not a valid JWT token"
            ) {
              return;
            }
            console.log(
              "Error deleting participant: ",
              err.response !== undefined ? err.response.data : err
            );
          });
      }
    }
  };

  const handleResetUsers = async (e) => {
    handleResetUserClose();
    for (let i in props.selectionModel) {
      const username = props.selectionModel[i];
      const accessToken = props.token.jwtToken;
      const row = props.tableData.find((x) => x.id === username);
      const requestData = {
        username: username,
        email: row.email,
        firstName: row.firstname,
        lastName: row.lastname,
        hvDate: row.homeVisitDate,
        hasAccount: row.hasAccount === "Yes" ? "1" : "0",
        hasAlexa: row.hasAlexa === "Yes" ? "1" : "0",
        existingAccount: row.existingAccount,
      };
      axios
        .post(`${REACT_APP_DB_ENDPOINT}/api/participants`, requestData, {
          headers: {
            accessToken: accessToken,
          },
        })
        .then((res) => {
          props.refreshTable(props.tableState);
        })
        .catch((error) => {
          console.log("Error resetting user " + username + " " + error);
        });
    }
  };

  const ResetButton = () => {
    return props.group.includes("super") ||
      props.tableState === UserMngmentTableState.DASHBOARD ? null : (
      <button
        disabled={!(props.selectionModel.length > 0)}
        onClick={handleResetUserOpen}
        className="control-button delete-user"
      >
        <FontAwesomeIcon icon={faUserMinus} className="control-icon" />
        Reset
      </button>
    );
  };

  const DeleteButton = () => {
    return props.group.includes("super") ? null : (
      <button
        disabled={!(props.selectionModel.length > 0)}
        onClick={handleDeleteUserOpen}
        className="control-button delete-user"
      >
        <FontAwesomeIcon icon={faUserMinus} className="control-icon" />
        Delete
      </button>
    );
  };

  return (
    <>
      <div className="user-controls">
        <DeleteButton />
        <ResetButton />
        <button className="control-button" onClick={handleAddUserOpen}>
          <FontAwesomeIcon icon={faUserPlus} className="control-icon" />
          {props.tableState === UserMngmentTableState.DASHBOARD
            ? "Add"
            : "Register"}
        </button>
        <button className="control-button" onClick={props.refreshTable}>
          <FontAwesomeIcon icon={faSyncAlt} className="control-icon" />
          Refresh
        </button>
        <DeleteUserDialog
          open={deleteUserOpen}
          onClose={handleDeleteUserClose}
          deleteUsers={handleDeleteUsers}
          tableState={props.tableState}
        />
        <ResetUserDialog
          open={resetUserOpen}
          onClose={handleResetUserClose}
          resetUsers={handleResetUsers}
        />
        {props.tableState === UserMngmentTableState.DASHBOARD ? (
          <AddUserDialog
            open={addUserOpen}
            onClose={handleAddUserClose}
            refreshTable={props.refreshTable}
          />
        ) : (
          <RegisterParticipantDialog
            token={props.token}
            open={addUserOpen}
            onClose={handleAddUserClose}
            refreshTable={props.refreshTable}
          />
        )}
      </div>
    </>
  );
};
