import React, { useState, useEffect } from "react";
import { UserTableControls } from "./UserTableControls";
import "./UsersCard.css";
import { UsersTable } from "./UsersTable";
import { getUsersWithGroups } from "./userFunctions";
import { Box, Grid, Typography, Switch, makeStyles } from "@material-ui/core";
import { UserMngmentTableState } from "../Visualizations/Tables/tableState";
import axios from "axios";

const useStyles = makeStyles(() => ({
  usersCard: {
    background: "#ececec",
    padding: "15px",
    paddingTop: "5px",
    overflow: "hidden",
  },
  tableControls: {
    padding: "15px",
  },
}));

const UsersCard = (props) => {
  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const { REACT_APP_DB_ENDPOINT } = process.env;
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableState, setTableState] = useState(UserMngmentTableState.DASHBOARD);
  const [checked, setChecked] = useState(false);
  const limit = 25;

  const handleSwitch = () => {
    if (checked) {
      setChecked(false);
      setTableState(UserMngmentTableState.DASHBOARD);
    } else {
      setChecked(true);
      setTableState(UserMngmentTableState.PARTICIPANTS);
    }
  };

  const refreshTable = async (tblState) => {
    setLoading(true);
    setRows([]);
    if (tblState === UserMngmentTableState.DASHBOARD) {
      getUsersWithGroups(limit)
        .then((response) => {
          formatTableData(response, tblState);
          setLoading(false);
        })
        .catch((error) => {
          alert(
            "There was an error encountered while attempting to refresh users"
          );
          setLoading(false);
        });
    } else {
      if (!props.refreshing) {
        const accessToken = props.token.jwtToken;
        axios
          .get(`${REACT_APP_DB_ENDPOINT}/api/participants`, {
            headers: {
              accessToken: accessToken,
            },
          })
          .then((res) => {
            formatTableData(res.data, tblState);
            setLoading(false);
          })
          .catch((err) => {
            if (
              err.response !== undefined &&
              err.response.message === "Not a valid JWT token"
            ) {
              return;
            }
            setLoading(false);
            console.log(
              "Error fetching total users:",
              err.response !== undefined ? err.response.data : err
            );
          });
      }
    }
  };

  const formatTableData = (response, tblState) => {
    let userRows = [];
    if (tblState === UserMngmentTableState.DASHBOARD) {
      response.forEach((user) => {
        if (user.Enabled) {
          let email = "";
          user.Attributes.forEach((attribute) => {
            if (attribute.Name === "email") {
              email = attribute.Value;
            }
          });

          let groups = user.Groups[0].GroupName;

          const dateCreated = new Date(user.UserCreateDate);
          const newObj = {
            id: user.Username,
            email: email,
            createdOn: dateCreated.toDateString(),
            groups: groups,
          };

          userRows.push(newObj);
        }
      });
    } else {
      const users = response.Users;
      users.forEach((user) => {
        const userObj = {
          id: "",
          email: "",
          firstname: "",
          lastname: "",
          username: "",
          registerDate: "",
          homeVisitDate: "",
          hasAlexa: "",
          hasAccount: "",
          cohortid: "",
        };

        user.Attributes.forEach((attribute) => {
          switch (attribute.Name) {
            case "email":
              userObj.email = attribute.Value;
              break;
            case "given_name":
              userObj.firstname = attribute.Value;
              break;
            case "family_name":
              userObj.lastname = attribute.Value;
              break;
            case "custom:hvdate":
              const hvDate = new Date(attribute.Value);
              userObj.homeVisitDate = hvDate.toDateString();
              break;
            case "custom:hasalexa":
              userObj.hasAlexa = attribute.Value === "1" ? "Yes" : "No";
              break;
            case "custom:hasaccount":
              userObj.hasAccount = attribute.Value === "1" ? "Yes" : "No";
              break;
            case "custom:cohortid":
              userObj.cohortid = attribute.Value;
              break;
            default:
              break;
          }
        });

        userObj.registerDate = user.UserCreateDate;
        userObj.username = user.Username;
        userObj.id = user.Username;

        userRows.push(userObj);
      });
    }

    setRows(userRows);
  };

  useEffect(() => {
    refreshTable(tableState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  return (
    <>
      <Box height="90vh" className={classes.usersCard}>
        <Grid
          container
          direction="row"
          alignContent="space-between"
          className={classes.tableControls}
        >
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              className={classes.switch}
            >
              <Grid item>
                <Typography variant="h6">Dashboard Users</Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={checked}
                  name="covidSwitch"
                  onChange={handleSwitch}
                  disabled={loading}
                ></Switch>
              </Grid>
              <Grid item>
                <Typography variant="h6">Study Participants</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserTableControls
              group={props.group}
              token={props.token}
              tableState={tableState}
              tableData={rows}
              selectionModel={selectionModel}
              refreshTable={refreshTable}
            />
          </Grid>
        </Grid>
        <UsersTable
          tableState={tableState}
          setSelectionModel={setSelectionModel}
          selectionModel={selectionModel}
          rows={rows}
          loading={loading}
        />
      </Box>
      {/* <div className="users-card"></div> */}
    </>
  );
};

export default UsersCard;
