export const getTotalReminders = (reminders) => {
  let total = 0;

  for (const [, value] of Object.entries(reminders)) {
    const setupReminder = value.hasreminders;

    if (setupReminder != null) {
      if (setupReminder) {
        total += 1;
      }
    }
  }

  return total;
};

export const getTotalHighPHQ8Scores = (moodScores) => {
  let depressedUsers = {};

  for (const [, value] of Object.entries(moodScores)) {
    const score = value.depression_score;
    if (score >= 10 && score !== null) {
      depressedUsers[value.userid] = true;
    }
  }
  return Object.keys(depressedUsers).length;
};

export const getTotalCovidReports = (covidTotals) => {
  const total = {};
  if (
    covidTotals.pastCovid !== undefined &&
    covidTotals.testedPos !== undefined
  ) {
    for (const [, value] of Object.entries(covidTotals["pastCovid"])) {
      if (value.everCovid != null || value.everPositive != null) {
        if (value.everCovid || value.everPositive) {
          total[value.userid] = 1;
        }
      }
    }

    for (const [, value] of Object.entries(covidTotals["testedPos"])) {
      if (value.testedpositive != null) {
        if (value.testedpositive) {
          total[value.userid] = 1;
        }
      }
    }
  }
  return Object.keys(total).length;
};
export const getTotalParticipants = (participants) => {
  return participants.length;
};

export const getTotalModdSupportRequests = (studySupport) => {
  let msRequests = 0;

  for (const [, value] of Object.entries(studySupport)) {
    if (value.type >= 5 && value.type <= 8) {
      msRequests += 1;
    }
  }

  return msRequests;
};

export const getTotalStudySupportRequests = (studySupport) => {
  let ssRequests = 0;

  for (const [, value] of Object.entries(studySupport)) {
    if (value.type === 12) {
      continue;
    }

    if (value.type < 5 || value.type > 8) {
      ssRequests += 1;
    }
  }
  return ssRequests;
};
