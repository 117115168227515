import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { authStatus } from "../../../authEnum";
import { validationErrorMessage } from "../validationEnums";
import "../SignIn.css";

const ResetPassForm = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errorMsg, setErrorMessage] = useState("");

  // handle email text field change
  const handlePassChange = (e) => {
    setNewPassword(e.target.value);
  };

  // handle password text field change
  const handleConfirmPassChange = (e) => {
    setConfirmPass(e.target.value);
  };

  // Submits request to aws to reset password. Sets the new auth state once completed
  const resetPassword = async (newPassword) => {
    try {
      const user = await Auth.completeNewPassword(props.tempUser, newPassword);
      Auth.currentAuthenticatedUser()
        .then(() => {
          Auth.currentSession()
            .then((session) => {
              props.setAccessToken(session.accessToken);
            })
            .catch((error) => console.log("Error getting session: ", error));
        })
        .catch((e) => {
          console.log("Error returning user session", e);
        });
      props.setAuthState({
        authStatus: authStatus.LOGGED_IN,
        user: user,
      });
    } catch (error) {
      console.log("Error thrown during completing of new password", error);
      setErrorMessage(error.message);
    }
  };

  // Handles password reset button click or submit. Performs validation on textfields.
  // calls reset password if text is valid.
  const handlePassReset = (e) => {
    e.preventDefault();

    // A field is empty
    if (newPassword === "" || confirmPass === "") {
      setErrorMessage(validationErrorMessage.EMPTY_RESET_PASS);
      return;
    }

    // New password does not equal confirm password
    if (newPassword !== confirmPass) {
      setErrorMessage(validationErrorMessage.PASS_NOT_MATCHING);
      return;
    }

    // Cannot reuse your curent password
    if (confirmPass === props.password) {
      setErrorMessage(validationErrorMessage.CANNOT_REUSE_CURR_PASS);
      return;
    }

    resetPassword(confirmPass);
  };

  return (
    <>
      <form onSubmit={handlePassReset}>
        <input
          className="textfield"
          type="password"
          id="pass"
          placeholder="Password"
          onChange={handlePassChange}
        />
        <input
          className="textfield"
          type="password"
          id="confirm-pass"
          placeholder="Confirm Password"
          onChange={handleConfirmPassChange}
        />
        <div className="error-message">{errorMsg}</div>
        <button
          className="login-button"
          id="reset-pass"
          type="button"
          onClick={(e) => {
            handlePassReset(e);
          }}
        >
          Reset Password
        </button>
      </form>
    </>
  );
};

export default ResetPassForm;
